
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
            <div v-if="user.user_role!='viewer'">
			<h1 class="mt-4 mb-5">{{$store.getters.language.data.employees.add_btn}}</h1>
			<form @submit.prevent="addEmployees" autocomplete="off">
				<v-layout row wrap>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_name" type="text" :label="$store.getters.language.data.employees.employee_name" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_birth" type="date" :label="$store.getters.language.data.employees.employee_birth" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="positions" v-model="employees.position_id" dense filled outlined item-text="position_name" item-value="position_id" :return-object="false" :label="$store.getters.language.data.positions.position_name">
						</v-select>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_contract_start_date" type="date" :label="$store.getters.language.data.employees.employee_contract_start_date" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_contract_end_date" type="date" :label="$store.getters.language.data.employees.employee_contract_end_date" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="['construction_site','office','showroom','mixed']" v-model="employees.employee_work_place" dense filled outlined item-text="employee_work_place" item-value="employee_work_place" :return-object="false" :label="$store.getters.language.data.employees.employee_work_place">
						</v-select>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_phone_1" type="textarea" :label="$store.getters.language.data.employees.employee_phone_1" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_phone_2" type="textarea" :label="$store.getters.language.data.employees.employee_phone_2" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="working_hours" v-model="employees.working_hour_id" dense filled outlined item-text="hours" item-value="working_hour_id" :return-object="false" :label="$store.getters.language.data.working_hours.title">
						</v-select>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="['active','terminated']" v-model="employees.employee_status" dense filled outlined item-text="employee_status" item-value="employee_status" :return-object="false" :label="$store.getters.language.data.employees.employee_status">
						</v-select>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_off_days_year" type="number" :label="$store.getters.language.data.employees.employee_off_days_year" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_off_hours_year" type="number" :label="$store.getters.language.data.employees.employee_off_hours_year" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_base_salary" type="text" :label="$store.getters.language.data.employees.employee_base_salary" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_food_budget" type="text" :label="$store.getters.language.data.employees.employee_food_budget" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_transportation_budget" type="text" :label="$store.getters.language.data.employees.employee_transportation_budget" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_phone_budget" type="text" :label="$store.getters.language.data.employees.employee_phone_budget" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_driver_budget" type="text" :label="$store.getters.language.data.employees.employee_driver_budget" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_government_insurance" type="text" :label="$store.getters.language.data.employees.employee_government_insurance" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_insurance_rate" type="text" :label="$store.getters.language.data.employees.employee_insurance_rate" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="positions" v-model="employees.supervisor_id" dense filled outlined item-text="position_name" item-value="position_id" :return-object="false" :label="$store.getters.language.data.employees.supervisor">
						</v-select>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="user" v-model="employees.user_id" dense filled outlined item-text="user_name" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_name" disabled>
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.employees.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
</div>
		</v-container>

		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/employees.request.js'
	import position_requests from './../../requests/positions.request.js'
	import working_hoursRequests from './../../requests/working_hours.request.js'
	export default {
		data() {
			return {
				employees: {},
				positions: {},
				working_hours: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_employees: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.employees.employee_name,
						align: 'start',
						sortable: true,
						value: 'employee_name',
					},
					{
						text: this.$store.getters.language.data.employees.employee_birth,
						align: 'start',
						sortable: true,
						value: 'employee_birth',
					},
					{
						text: this.$store.getters.language.data.positions.position_id,
						align: 'start',
						sortable: true,
						value: 'position_id',
					},
					{
						text: this.$store.getters.language.data.employees.employee_work_place,
						align: 'start',
						sortable: true,
						value: 'employee_work_place',
					},
					{
						text: this.$store.getters.language.data.employees.employee_phone_1,
						align: 'start',
						sortable: true,
						value: 'employee_phone_1',
					},
					{
						text: this.$store.getters.language.data.employees.employee_phone_2,
						align: 'start',
						sortable: true,
						value: 'employee_phone_2',
					},
					{
						text: this.$store.getters.language.data.working_hours.working_hour_id,
						align: 'start',
						sortable: true,
						value: 'working_hour_id',
					},
					{
						text: this.$store.getters.language.data.employees.employee_status,
						align: 'start',
						sortable: true,
						value: 'employee_status',
					},
					{
						text: this.$store.getters.language.data.employees.employee_off_days_year,
						align: 'start',
						sortable: true,
						value: 'employee_off_days_year',
					},
					{
						text: this.$store.getters.language.data.employees.employee_off_hours_year,
						align: 'start',
						sortable: true,
						value: 'employee_off_hours_year',
					},
					{
						text: this.$store.getters.language.data.employees.employee_base_salary,
						align: 'start',
						sortable: true,
						value: 'employee_base_salary',
					},
					{
						text: this.$store.getters.language.data.employees.employee_food_budget,
						align: 'start',
						sortable: true,
						value: 'employee_food_budget',
					},
					{
						text: this.$store.getters.language.data.employees.employee_transportation_budget,
						align: 'start',
						sortable: true,
						value: 'employee_transportation_budget',
					},
					{
						text: this.$store.getters.language.data.employees.employee_phone_budget,
						align: 'start',
						sortable: true,
						value: 'employee_phone_budget',
					},
					{
						text: this.$store.getters.language.data.employees.employee_driver_budget,
						align: 'start',
						sortable: true,
						value: 'employee_driver_budget',
					},
					{
						text: this.$store.getters.language.data.employees.employee_government_insurance,
						align: 'start',
						sortable: true,
						value: 'employee_government_insurance',
					},
					{
						text: this.$store.getters.language.data.employees.employee_insurance_rate,
						align: 'start',
						sortable: true,
						value: 'employee_insurance_rate',
					},
					{
						text: this.$store.getters.language.data.users.user_id,
						align: 'start',
						sortable: true,
						value: 'user_id',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'employee_id',
					}
				],
			}
		},
		computed: {
			// positions(){
			//     return this.$store.getters.positions_list
			// },
			// working_hours(){
			//     return this.$store.getters.working_hours_list
			// },
			// users() {
			// 	return this.$store.getters.users_list
			// },
			user() {
				return this.$store.getters.user
			}
		},
		mounted() {

			this.readPositions();
		},
		methods: {
			readWorkingHours() {
				this.loading = true
				working_hoursRequests.getAllWorkingHours().then(r => {
					if (r.status == 200) {
						this.working_hours = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read WorkingHours',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read WorkingHours',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			readPositions() {
				this.loading = true
				position_requests.getAllPositions().then(r => {
					if (r.status == 200) {
						this.employees.user_id = this.user.user_id
						this.positions = r.data.rows
						this.readWorkingHours()
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Positions',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Positions',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			addEmployees() {
				this.loading_btn = true

				requests.createEmployees(this.employees).then(r => {
					if (r.status == 200) {
						this.employees = {
							user_id: this.user.user_id
						}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'Employees Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add Employees',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteEmployees(employee_id) {
				requests.deleteEmployees(employee_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.employee_id != employee_id
						})
						this.snackbar = {
							value: true,
							text: 'Employees Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteEmployeesList() {
				let ids = this.selected_rows.map(m => m.employee_id)
				requests.deleteEmployeesList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.employee_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' Employees Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readEmployees() {
				this.loading = true
				requests.getAllEmployees().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectEmployees(i) {
				this.selected_employees = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    